app-login {
    background-color: #fff;
}

.cmpt-login {
    position: relative;
    max-width: 320px;
    box-shadow: 0px 0px 5px #a8a8a8;
    border-radius: 5px;
    background-color: #29b9e1;
    overflow: scroll;
    .img-container {
        //height: 260px;
        padding: 35px;
        img {
            /*  height: 260px;
            -webkit-clip-path: polygon(0 0, 0 260px, 100% 153px, 100% 0);
            clip-path: polygon(0 0, 0 260px, 100% 153px, 100% 0);
            object-fit: cover; */
            width: 100%;
        }
    }

    .img-logo {
        width: 100%;
        position: absolute;
        top: 181px;
        text-align: center;
        img {
            width: 65px;
        }
    }

    .input-login {
        .input-format {
            padding: 20px;
            color: #000;
            label {
                width: 100%;
                display: block;
            }

            input {
                width: 100%;
                background-color: transparent;
                border: none;
                border-bottom: 1px solid #535353;
                outline: none;
                padding-bottom: 10px;
            }

            select {
                width: 100%;
                border: none;
                background-color: #fff;
                font-size: 15px;
                padding: 8px 8px 8px 0px;
                font-family: sans-serif;
                //border-radius: 4px;
                border-bottom: 1px solid #123;
                outline: none;
            }
        }

        .input-format-dual {
            padding: 20px;
            color: #000;
            label {
                width: 100%;
                display: block;
            }

            input {
                width: 90%;
                background-color: transparent;
                border: none;
                border-bottom: 1px solid #535353;
                outline: none;
                padding-bottom: 10px;
                display: inline-block;
                vertical-align: middle;
            }

            .icon-input {
                width: 10%;
                display: inline-block;
                vertical-align: middle;
                ion-icon {
                    font-size: 25px;
                }
            }
        }

        .btn-ind {
            text-align: center;
            //padding: 20px;
            padding: 20px 20px 0 20px;
            button {
                width: 100%;
                font-size: 17px;
                padding: 12px;
                position: relative;
                border-radius: 40px;
                background-color: #203a78;
                -webkit-transition-duration: 0.4s;
                transition-duration: 0.4s;
                text-decoration: none;
                overflow: hidden;
                cursor: pointer;
                color: #fff;
                text-transform: uppercase;
            }

            button:after {
                content: "";
                background: #000;
                display: block;
                position: absolute;
                padding-top: 300%;
                padding-left: 350%;
                margin-left: -20px !important;
                margin-top: -120%;
                opacity: 0;
                transition: all 0.8s;
            }

            button:active:after {
                padding: 0;
                margin: 0;
                opacity: 1;
                transition: 0s;
            }
        }

        .btn-dual {
            text-align: center;
            padding: 0 20px 20px 20px;

            p {
                cursor: pointer;
            }

            button {
                display: inline-block;
                vertical-align: middle;
                font-size: 17px;
                padding: 7px;
                border-radius: 4px;
                margin-left: 1%;
                margin-right: 1%;
                width: 48%;
                position: relative;
                // transition-duration: 0.4s;
                ion-icon {
                    font-size: 25px;
                    color: #fff;
                }
            }

            button:nth-child(2) {
                background-color: #d64a37;
            }

            button:nth-child(1) {
                background-color: #0777e8;
            }

            p {
                margin: 0;
                font-size: 12px;
                color: #006fae;
                display: inline-block;
                width: 48%;
                margin-left: 1%;
                margin-right: 1%;
            }
        }
    }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.cmpt-login::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.cmpt-login {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

@media (max-width: 450px) {
    .cmpt-login {
        max-width: 100%;
        box-shadow: unset;
        border: none;
        height: 100%;
        align-items: center;
        justify-content: center;
    }
}

.lgn {
    display: flex;
    justify-content: center;
    /* max-width: 300px; */
    box-shadow: 1px 1px 6px #797979;
    border-radius: 5px;
    align-items: center;
    height: 100%;
    width: 100%;

    position: relative;
}

.lgn:before {
    content: "";
    filter: blur(5px) grayscale(70%);
    background-image: url(./../../assets/imgs/login/fondoSham.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.inc {
    color: #64848c !important;
    .icon-inner {
        outline: none;
        svg {
            stroke: #64848c !important;
        }
    }
}

.olvidado {
    margin: 0;
    font-size: 14px;
    color: #596a6c;
    text-align: center;
    font-style: italic;
    cursor: pointer;
}

.o {
    margin: 0;

    .lineas {
        color: #596a6c;
        padding: 5px 25px;
    }

    .lineas:after {
        content: "";
        border-bottom: 1px solid #596a6c;
        width: 46%;
        display: inline-block;
        vertical-align: middle;
        margin: 1%;
    }
    .lineas:before {
        content: "";
        border-bottom: 1px solid #596a6c;
        width: 46%;
        display: inline-block;
        vertical-align: middle;
        margin: 1%;
    }
}

.no-registrado {
    text-align: center;
    font-size: 16px;
    color: #414e4f;

    strong {
        font-weight: 400;
        text-decoration: underline;
        cursor: pointer;
    }
    strong:hover{
        opacity: 0.6;
    }
}

.terminos {
    text-align: center;
    font-size: 12px;
    color: #414e4f;
    line-height: 14px;
    text-decoration: underline;
    margin-bottom: 30px;
}
